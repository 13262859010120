<!--
 * @Author: 刘康宁 869161591@qq.com
 * @Date: 2022-07-21 10:10:23
 * @LastEditors: YGQ
 * @LastEditTime: 2025-03-07 16:24:39
 * @FilePath: /oneid/src/views/home/dashboard.vue
 * @Description: 工作台
-->
<template>
  <div>
    <img class="bg" src="@/assets/dashboard/bg.webp" />
    <Header />
    <div class="font20 N2 bold" style="margin: 8px 0 32px">
      请选择需要进入的应用
    </div>
    <div class="content">
      <div class="flex-wrap">
        <AppCard v-for="app in index" :key="app.title" :app="app" />
      </div>
    </div>
    <DialogPassword
      :visible="dialogName == 'password'"
      :closable="false"
      @hide="dialogName = null"
    />
  </div>
</template>

<script>
import Header from "@/components/Header";
import AppCard from "./components/AppCard";
import DialogPassword from "./components/DialogPassword";

export default {
  components: {
    Header,
    AppCard,
    DialogPassword,
  },
  data() {
    return {
      index: [
        {
          id: "pms",
          title: "云PMS系统",
          note: "酒店日常运营管理工具",
          url: {
            release: {
              MR: "https://pms.geemro.com/pms/#/oneid",
              BY: "https://pms.geemro.cn/pms/#/oneid",
            }, // MR默认线路 BY备用
            trial: { MR: "https://trial.geemro.com/pms/#/oneid" },
            develop: { MR: "https://dev.geemro.com/pms/#/oneid" },
            local: { MR: "https://dev.geemro.com/pms/#/oneid" },
          },
          color: "#5ea9fb",
          bgColor: "linear-gradient(241deg, #5798F7 0%, #3385F6 100%)",
        },
        {
          id: "onedata",
          title: "报表中心",
          note: "查询运营数据报表与图形报表",
          url: {
            release: {
              MR: "https://onedata.geemro.com/onedata/#/oneid",
              BY: "https://onedata.geemro.com/onedata/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/onedata/#/oneid" },
            develop: { MR: "https://dev.geemro.com/onedata/#/oneid" },
            local: { MR: "https://dev.geemro.com/onedata/#/oneid" },
          },
          color: "#41BA6E",
          bgColor: "linear-gradient(241deg, #37BE70 0%, #3AB566 100%)",
        },
        {
          id: "pms-manage",
          title: "PMS管理后台",
          note: "酒店云PMS后台管理系统",
          url: {
            release: {
              MR: "https://pms.geemro.com/pms-manage/#/oneid",
              BY: "https://pms.geemro.cn/pms-manage/#/oneid",
            }, // release = 生产环境
            trial: { MR: "https://trial.geemro.com/pms-manage/#/oneid" }, // trial = 测试环境
            develop: { MR: "http://dev.geemro.com/pms-manage/#/oneid" }, // develop = 开发环境
            local: { MR: "https://dev.geemro.com/pms-manage/#/oneid" },
          },
          color: "#e98343",
          bgColor: "linear-gradient(241deg, #EDA65B 0%, #D07E3B 100%)",
        },
        {
          id: "cms",
          title: "CMS集团管理后台",
          note: "集团体系的管控与设置后台",
          url: {
            release: {
              MR: "https://pms.geemro.com/cms/#/oneid",
              BY: "https://pms.geemro.cn/cms/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/cms/#/oneid" },
            develop: { MR: "https://dev.geemro.com/cms/#/oneid" },
            local: { MR: "https://dev.geemro.com/cms/#/oneid" },
          },
          color: "#4ba691",
          bgColor: "linear-gradient(241deg, #48B69B 0%, #3A8A7D 100%)",
        },
        {
          id: "unit-crm",
          title: "会员CRM",
          note: "个人会员和企业会员的精细化管理平台",
          url: {
            release: {
              MR: "https://pms.geemro.com/unit-crm/#/oneid",
              BY: "https://pms.geemro.cn/unit-crm/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/unit-crm/#/oneid" },
            develop: { MR: "https://dev.geemro.com/unit-crm/#/oneid" },
            local: { MR: "https://dev.geemro.com/unit-crm/#/oneid" },
          },
          color: "#8D5EFD",
          bgColor: "linear-gradient(59deg, #8C4EE4 4%, #7E8AF1 97%)",
        },
        {
          id: "pms-activity",
          title: "营销中心",
          note: "集团营销策略的集成化管理中心",
          url: {
            release: {
              MR: "https://pms.geemro.com/pms-activity/#/oneid",
              BY: "https://pms.geemro.cn/pms-activity/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/pms-activity/#/oneid" },
            develop: { MR: "https://dev.geemro.com/pms-activity/#/oneid" },
            local: { MR: "https://dev.geemro.com/pms-activity/#/oneid" },
          },
          color: "#dc744e",
          bgColor: "linear-gradient(241deg, #F7AE83 0%, #D65052 100%)",
        },
        {
          id: "ics",
          title: "库存管理",
          note: "酒店客房和餐饮业务的物资库存管理系统",
          url: {
            release: {
              MR: "https://pms.geemro.com/ics/#/oneid",
              BY: "https://pms.geemro.cn/ics/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/ics/#/oneid" },
            develop: { MR: "https://dev.geemro.com/ics/#/oneid" },
            local: { MR: "https://dev.geemro.com/ics/#/oneid" },
          },
          color: "#34B58C",
          bgColor: "linear-gradient(241deg, #7DAED1 2%, #2FA580 100%)",
        },
        {
          id: "cbs",
          title: "成本与预算管理",
          note: "酒店成本与预算控制数字化平台",
          url: {
            release: {
              MR: "https://pms.geemro.com/cbs/#/oneid",
              BY: "https://pms.geemro.cn/cbs/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/cbs/#/oneid" },
            develop: { MR: "https://dev.geemro.com/cbs/#/oneid" },
            local: { MR: "https://dev.geemro.com/cbs/#/oneid" },
          },
          color: "#34B58C",
          bgColor: "linear-gradient(61deg, #C26799 0%, #E7B18F 100%)",
        },
        {
          id: "ota",
          title: "OTA直连",
          note: "酒店产品与OTA平台的全直连功能",
          url: {
            release: {
              MR: "https://pms.geemro.com/ota/#/oneid",
              BY: "https://pms.geemro.cn/ota/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/ota/#/oneid" },
            develop: { MR: "https://dev.geemro.com/ota/#/oneid" },
            local: { MR: "https://dev.geemro.com/ota/#/oneid" },
          },
          color: "#6465e7",
          bgColor: "linear-gradient(60deg, #CE3FB6 4%, #FF9071 99%)",
        },
        {
          id: "rms",
          title: "收益管理系统",
          note: "智能生成酒店收益最优策略",
          url: {
            release: {
              MR: "https://pms.geemro.com/rms/#/oneid",
              BY: "https://pms.geemro.cn/rms/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/rms/#/oneid" },
            develop: { MR: "https://dev.geemro.com/rms/#/oneid" },
            local: { MR: "https://dev.geemro.com/rms/#/oneid" },
          },
          color: "#5ea9fb",
          bgColor: "linear-gradient(241deg, #6DA6FA 0%, #1E75ED 97%)",
        },
        {
          id: "invoice",
          title: "智慧发票",
          note: "直连数电发票以及客人扫码申请开票功能",
          url: {
            release: {
              MR: "https://pms.geemro.com/invoice/#/oneid",
              BY: "https://pms.geemro.cn/invoice/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/invoice/#/oneid" },
            develop: { MR: "https://dev.geemro.com/invoice/#/oneid" },
            local: { MR: "https://dev.geemro.com/invoice/#/oneid" },
          },
          color: "#A1837B",
          bgColor: "linear-gradient(241deg, #A88A7F 0%, #967870 100%)",
        },
        // {
        //   id: "hotel",
        //   title: "酒店信息管理系统",
        //   note: "官渠酒店信息与图片的管理发布平台",
        //   url: {
        //     release: {
        //       MR: "https://pms.geemro.com/hotel/#/oneid",
        //       BY: "https://pms.geemro.cn/hotel/#/oneid",
        //     },
        //     trial: { MR: "https://trial.geemro.com/hotel/#/oneid" },
        //     develop: { MR: "https://dev.geemro.com/hotel/#/oneid" },
        //     local: { MR: "https://dev.geemro.com/hotel/#/oneid" },
        //   },
        //   color: "#209cff",
        //   bgColor: "linear-gradient(241deg, #2E8AFC 0%, #2E8AFC 100%)",
        // },
        {
          id: "qcs",
          title: "门店质检",
          note: "质检/整改/复查全流程闭环的品质巡检系统",
          url: {
            release: {
              MR: "https://work.geemro.com/qcs/#/oneid",
              BY: "https://work.geemro.com/qcs/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/qcs/#/oneid" },
            develop: { MR: "https://dev.geemro.com/qcs/#/oneid" },
            local: { MR: "https://dev.geemro.com/qcs/#/oneid" },
          },
          color: "#6465e7",
          bgColor: "linear-gradient(241deg, #7468EF 0%, #5F4BF2 100%)",
        },
        {
          id: "scrm",
          title: "SCRM客户管理",
          note: "客历档案与社交客户管理中心",
          url: {
            release: {
              MR: "https://pms.geemro.com/scrm-manage/#/oneid",
              BY: "https://pms.geemro.cn/scrm-manage/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/scrm-manage/#/oneid" },
            develop: { MR: "https://dev.geemro.com/scrm-manage/#/oneid" },
            local: { MR: "https://dev.geemro.com/scrm-manage/#/oneid" },
          },
          color: "#e08e56",
          bgColor: "linear-gradient(59deg, #8C4EE4 4%, #7E8AF1 97%)",
        },
        {
          id: "rcs",
          title: "风险管控平台",
          note: "酒店运营风险监控与预警平台",
          url: {
            release: {
              MR: "https://pms.geemro.com/rcs/#/oneid",
              BY: "https://pms.geemro.cn/rcs/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/rcs/#/oneid" },
            develop: { MR: "https://dev.geemro.com/rcs/#/oneid" },
            local: { MR: "https://dev.geemro.com/rcs/#/oneid" },
          },
          color: "#e08e56",
          bgColor: "linear-gradient(241deg, #7DAED1 2%, #2FA580 100%)",
        },
        {
          id: "union",
          title: "酒店联盟",
          note: "杰姆龙酒店联盟合作伙伴后台",
          url: {
            release: {
              MR: "https://pms.geemro.com/union/#/oneid",
              BY: "https://pms.geemro.cn/union/#/oneid",
            },
            trial: { MR: "https://trial.geemro.com/union/#/oneid" },
            develop: { MR: "https://dev.geemro.com/union/#/oneid" },
            local: { MR: "https://dev.geemro.com/union/#/oneid" },
          },
          color: "#e08e56",
          bgColor: "linear-gradient(241deg, #DA824F 0%, #E4494C 100%)",
        },
      ],
      dialogName: null,
    };
  },

  mounted() {
    // 备用线路只显示PMS一个入口
    if (localStorage.HOST_CODE == "BY") {
      this.index.splice(3, this.index.length - 1);
    }
    const { passwordModifyTime } = JSON.parse(localStorage.getItem("user"));
    const now = new Date().getTime(); // 当前时间
    const past = new Date(passwordModifyTime).getTime(); // 密码设置时间
    if (now - past > 86400e3 * 91) {
      this.dialogName = "password"; // 超过 91 天未更改密码, 则弹出改密弹框
    }
  },
};
</script>

<style scoped>
@import "../../styles/dashboard-bg.scss";
.content {
  margin: 0 auto;
  width: 1200px;
}
.title {
  padding: 24px 16px;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>
